









































































































































































































































































































































import { CustomField, CustomFieldTypeEnum } from '@/utils/customFields'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsFixedBottomActions from '@/components/VsFixedBottomActions/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCustomFieldGenerator from '@/modules/lists/components/VsCustomFieldGenerator/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { get, isEqual, orderBy } from 'lodash'
import {
    IContact,
    ContactStatusEnum,
    ContactBounceEnum,
} from '@/api/consoleApi/types'
import SubscriberAreaTop from '@/modules/subscriberArea/components/SubscriberAreaTop/Index.vue'
import { changeContactStatus, forgetContact, getSubscriberRecipient, getSubscriber, getSubscriberSuppression, getListCustomFields, updateContact } from '@/api/consoleApi/subscriberArea'
import { SubscriberAreaModule } from '@/store/modules/subscriberArea'
import moment from 'moment'

@Component({
    name: 'ContactDetails',
    components: {
        VsContainer,
        VsSidebarLayout,
        VsFullTopBarLayout,
        MyAccordion,
        VsDropdownButton,
        VsCustomFieldGenerator,
        VsSectionHeader,
        VsFixedBottomActions,
        VsLoader,
        SubscriberAreaTop,
        VsConfirm,
    },
})
export default class extends Vue {
    list: any = null
    smsSup = false
    emailSup = false
    customFields: CustomField[] = []
    emailSuppression: any = null
    smsSuppression: any = null
    customFieldsValues: {[key: string]: any} | null = null
    startCustomFieldsValues: {[key: string]: any} | null = null
    private loading = false
    private loadingCf = false
    private showDetails = false
    private loadingOptIn = false
    private automationBehaviorsTriggers = false
    private sendConfirmationEmailTrigger = false
    contact: IContact | null = null

    $refs!: any

    get listName () {
        return this.list?.name || 'Lista non trovata'
    }

    get subscriberAreaUser () {
        return SubscriberAreaModule.user
    }

    get userContact () {
        return this.subscriberAreaUser?.credential || ''
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get areValuesDifferent () {
        return !isEqual(this.customFieldsValues, this.startCustomFieldsValues)
    }

    get isOptIn () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.optIn
    }

    get isSubscribed () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.subscribed
    }

    get isUnsubscribed () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.unsubscribed
    }

    get isOptOut () {
        if (!this.contact) return false
        return this.contact.subscription.status === ContactStatusEnum.optOut
    }

    get getUnsubscriptionMotivation () {
        if (this.contact?.subscription.status !== 'Unsubscribed') return
        return this.$t(`lists.contact.unsubscriptionMotivations[${parseInt(this.contact.unsubscription.motivation) - 1}]`)
    }

    beforeMount () {
        this.getList()
        this.getSubscriber()
        this.getSubscriberSuppression()
    }

    private async submitForm () {
        if (this.loading) return
        const isValid = await this.$refs.contactForm.validate()
        if (!isValid) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del contatto, ricontrolla i campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.loading = true
        try {
            await updateContact(this.listId, this.contactId, this.generateSubscribeData())
            this.$root.$vsToast({
                heading: 'Contatto modificato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getSubscriber()
            this.generateCustomFields()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del contatto, ricontrolla i campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            this.addServerErrors(e)
        }
        this.loading = false
    }

    private addServerErrors (e: unknown) {
        if (axios.isAxiosError(e)) {
            if (e.response?.status === 400) {
                const message = e.response?.data?.message
                if (message) {
                    const fieldNames = Object.keys(this.customFieldsValues || {})
                    for (const error in message) {
                        if (error.includes('custom_fields.')) {
                            const errArr = error.split('.')
                            this.$refs.contactForm.setErrors({
                                [fieldNames[parseInt(errArr[1])]]: this.getMessageError(message[error][0]),
                            })
                        }
                        if (error.includes('mobile')) {
                            this.$refs.contactForm.setErrors({
                                mobile: this.getMessageError(message[error][0]),
                            })
                        }
                        if (error.includes('email_address')) {
                            this.$refs.contactForm.setErrors({
                                email: this.getMessageError(message[error][0]),
                            })
                        }
                    }
                }
            }
        }
    }

    /* questa parte deve diventare una utils con le stringhe definite in base alla stringa match esatto */
    private getMessageError (messageError: string) {
        if (!messageError) return 'Il campo non ha un formato valido'

        if (messageError.includes('mobile number is not valid')) {
            return this.$t('customValidations.libphoneNumberMobile')
        }
        if (messageError.includes('has already been taken.')) {
            return 'Il valore inserito deve essere univoco'
        }
        if (messageError.includes('field is required.')) {
            return 'Il campo è obbligatorio'
        }
        if (messageError.includes('must be a number.')) {
            return 'Il campo deve essere un numero'
        }
        if (messageError.includes('field must be true or false.')) {
            return 'Il campo deve essere di tipo buleano'
        }
        if (messageError.includes('must be a valid email address.')) {
            return 'Il campo deve essere un indirizzo email valido'
        }
        if (messageError.includes('must be valid date (format YYYY-MM-DD)') || messageError.includes('is not a valid date.')) {
            return 'Il campo deve essere una data valida (formato YYYY-MM-DD)'
        }
        if (messageError.includes('must be valid time string (HH:MM:SS)')) {
            return 'Il campo non ha un formato di tipo temporale valido (formato HH:MM:SS)'
        }
        if (messageError.includes('must be a valid IP address.')) {
            return 'Il campo deve essere un indirizzo IP valido'
        }

        return 'Il campo non ha un formato valido'
    }

    private generateSubscribeData () {
        return {
            mobile: this.customFieldsValues?.mobile,
            email_address: this.customFieldsValues?.email,
            custom_fields: this.generateCustomFieldsFromValues(),
        }
    }

    private generateCustomFieldsFromValues () {
        const customFields = []
        for (const fieldId in this.customFieldsValues) {
            if (['mobile', 'email'].includes(fieldId)) continue
            customFields.push({
                id: parseInt(fieldId),
                value: this.parseEmptyArrayValues(this.customFieldsValues[fieldId]),
            })
        }
        return customFields
    }

    private parseEmptyArrayValues (value: any) {
        if (Array.isArray(value) && value.length === 0) return null
        return value
    }

    private resetTriggers () {
        this.automationBehaviorsTriggers = false
        this.sendConfirmationEmailTrigger = false
    }

    async subscribeContact () {
        try {
            await this.$refs.subscribeContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await changeContactStatus(this.listId, this.contactId, {
                status: 'Subscribed',
                timestamp: moment().unix(),
            })
            this.$root.$vsToast({
                heading: 'Contatto iscritto con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getSubscriber()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\' iscrizione del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async unsubscribeContact () {
        try {
            await this.$refs.unsubscribeContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await changeContactStatus(this.listId, this.contactId, {
                status: 'Unsubscribed',
                timestamp: moment().unix(),
            })
            this.$root.$vsToast({
                heading: 'Contatto disiscritto con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getSubscriber()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la disiscrizione del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async forgetContact () {
        try {
            await this.$refs.forgetContactConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await forgetContact()
            this.$root.$vsToast({
                heading: 'La tua richiesta di cancellazione è andata a buon fine',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            const userMongoId = this.subscriberAreaUser?.userMongoId || ''
            SubscriberAreaModule.logout()
            this.$router.replace({
                name: 'subscriberAreaLogin',
                params: {
                    userId: userMongoId,
                },
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dei dati del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    @Watch('listId', { immediate: false })
    private async getList () {
        try {
            const resp = await getSubscriberRecipient(this.listId)
            this.list = resp.data.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Lista non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'subscriberAreaIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    @Watch('contactId', { immediate: false })
    @Watch('listId', { immediate: false })
    private async getSubscriber () {
        try {
            const resp = await getSubscriber(this.listId, this.contactId)
            console.log(resp.data.data)
            this.contact = resp.data.data as IContact
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404 || e.response?.status === 401) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Contatto non trovato',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'subscriberAreaIndex' })
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore nel reperire i dati della lista, riprova più tardi',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    @Watch('listId', { immediate: true })
    private async generateCustomFields () {
        this.loadingCf = true
        try {
            this.customFieldsValues = null
            this.startCustomFieldsValues = null
            await this.getCustomFields()
            this.generateCustomFieldsValuesObject()
            // this.getSuppressions()
        } catch (e) {
            console.log(e)
        }
        this.loadingCf = false
    }

    private async getCustomFields () {
        const resp = await getListCustomFields(this.listId, {
            limit: 1000,
        })
        this.customFields = orderBy(resp.data.data, 'validation.required', 'desc')
    }

    // private async getContact () {
    //     try {
    //         const resp = await getListContactById(this.listId, this.contactId, { include: 'tags' })
    //         this.contact = resp.data.data
    //     } catch (e) {
    //         const errorCode = get(e, 'response.status', 0)
    //         if (errorCode === 404) {
    //             this.$router.replace({
    //                 name: 'contacts',
    //             })
    //         }
    //         console.log(e)
    //     }
    // }

    private generateCustomFieldsValuesObject () {
        if (!this.contact) return
        const newCustomFieldsValues: {[key: string]: any} = {}
        for (const field of this.customFields) {
            newCustomFieldsValues[field.id + ''] = this.getCustomFieldStartValue(field)
        }
        this.customFieldsValues = newCustomFieldsValues
        this.startCustomFieldsValues = JSON.parse(JSON.stringify(newCustomFieldsValues))
    }

    private getCustomFieldStartValue (customField: CustomField) {
        if (!this.contact) return ''
        if (customField.type === CustomFieldTypeEnum.email) return this.contact.email_address
        if (customField.type === CustomFieldTypeEnum.mobile) return this.contact.mobile

        const findCfValue = this.contact.custom_fields.find((el: any) => el.id === customField.id)

        if (findCfValue && findCfValue.value && findCfValue.value !== '0000-00-00') return findCfValue.value

        if (customField.default_value) return customField.default_value
        if (
            customField.type === CustomFieldTypeEnum.checkboxes
        ) {
            return []
        }
        return ''
    }

    async getSubscriberSuppression () {
        try {
            const resp = await getSubscriberSuppression()
            for (const supp of resp.data.data) {
                if (supp.email_address) {
                    this.emailSup = true
                }
                if (supp.mobile) {
                    this.smsSup = true
                }
            }
        } catch (e) {
            console.log(e)
            this.emailSup = false
            this.smsSup = false
        }
    }
}
