var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
        boundary: 'window',
        interactive: true,
    }),expression:"{\n        boundary: 'window',\n        interactive: true,\n    }"}],staticClass:"vs-w-full vs-inline-flex vs-items-center vs-cursor-pointer vs-gap-2 hover:vs-bg-grey-100 vs-px-1",attrs:{"content":_vm.label},on:{"click":_vm.togglePopover}},[_c('span',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-truncate",class:_vm.textClass,attrs:{"title":_vm.textTip}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('VsPopover',{ref:"dropdownTextMenu",class:{
            'vs-max-w-full': true,
            'vs-pointer-events-none': _vm.disabled,
        },attrs:{"placement":_vm.placement,"data-html2canvas-ignore":"","boundary":_vm.boundary,"appendTo":_vm.appendTo},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VsIcon',{ref:"activator",staticClass:"vs-flex-none",attrs:{"size":_vm.iconSize,"name":_vm.iconName}})]},proxy:true},{key:"default",fn:function(ref){
        var closePopover = ref.closePopover;
return [_c('div',{staticClass:"vs-py-2",style:(("min-width: " + _vm.minWidth + ";max-height: " + _vm.maxHeight)),on:{"click":closePopover}},[_vm._t("default")],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }