import { CustomField } from '@/utils/customFields'
export enum ContactStatusEnum {
    subscribed = 'Subscribed',
    optIn = 'Opt-In Pending',
    unsubscribed = 'Unsubscribed',
    optOut = 'Opt-Out Pending'
}

export enum ContactBounceEnum {
    bounced = 'Bounced',
    notBounced = 'Not Bounced',
}

export interface ICustomFieldValue extends CustomField {
    value: any
}

export interface IContact {
    id: number
    recipient_id: number
    email_address: string
    mobile: string
    bounce: ContactBounceEnum.bounced | ContactBounceEnum.notBounced
    opt_in_date: string
    tags: {
        data: any[]
    }
    subscription: {
        status: ContactStatusEnum.subscribed | ContactStatusEnum.optIn | ContactStatusEnum.unsubscribed | ContactStatusEnum.optOut
        date: string
        ip: string
    }
    unsubscription: {
        date: string
        ip: string
        motivation: string
    }
    custom_fields: ICustomFieldValue[]
}
