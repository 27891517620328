








































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsDropdownText',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: '',
    }) appendTo?: string

    @Prop({
        required: false,
        default: 'chevron-down',
    }) iconName?: string

    @Prop({
        required: false,
        default: '6',
    }) iconSize?: string

    @Prop({
        required: false,
        default: '',
    }) textClass?: string

    @Prop({
        required: false,
        default: '',
    }) textTip?: string

    @Prop({
        required: false,
        default: 'bottom-start',
    }) placement?: string

    @Prop({
        required: false,
        default: 'scrollParent',
    }) boundary?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) disabled?: boolean

    @Prop({
        required: false,
        default: '160px',
    }) minWidth?: string

    @Prop({
        required: false,
        default: 'none',
    }) maxHeight?: string

    $refs: any

    togglePopover () {
        if (!this.$refs?.dropdownTextMenu?.$refs?.tip?.tip) return
        this.$refs.dropdownTextMenu.$refs.tip.tip.show()
    }
}
